<template>
  <div>
    <page-header :items="items" />
    <validation-observer ref="simpleRules">
      <div class="card">
        <div class="card-body">
          <div
            v-if="isCaseReported"
            class="card invoice-details"
            style="border: 1px solid #404656"
          >
            <div
              class="card-header d-flex align-items-center justify-content-start gap-2"
            >
              <h3>{{ $t("Connected Case") }}</h3>
              <div class="">
                <p class="status-tag" v-if="reportCaseID.status">
                  {{ reportCaseID.status }}
                </p>
              </div>
            </div>
            <div class="card-body px-3">
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div
                    class="d-flex align-items-center justify-content-between"
                    style="margin-bottom: 8px"
                  >
                    <h5>{{ $t("Case Name") }}</h5>
                    <p v-if="reportCaseID.name">
                      {{ reportCaseID.name }}
                    </p>
                    <p v-else>-</p>
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <h5>{{ $t("Case Status") }}</h5>

                    <p
                      class="status-tag text-center"
                      v-if="reportCaseID.caseStatus"
                    >
                      {{ reportCaseID.caseStatus }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-row>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <div class="form-group">
                <ElLabel
                  class="form-label"
                  for-label="title"
                  :title="$t('Title')"
                  :is-required-icon="true"
                />
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <input
                    id="title"
                    v-model="form.title"
                    name="title"
                    class="form-control"
                    :disabled="btnLoading"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <div class="form-group">
                <ElLabel
                  class="form-label"
                  for-label="priorty"
                  :title="$t('Priority')"
                  :is-required-icon="true"
                />
                <validation-provider
                  #default="{ errors }"
                  name="Priority"
                  rules="required"
                >
                  <select
                    class="form-control"
                    v-model="form.priority"
                    :disabled="btnLoading"
                  >
                    <option value="low">{{ $t("Low") }}</option>
                    <option value="normal">{{ $t("Normal") }}</option>
                    <option value="high">{{ $t("High") }}</option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" lg="6" md="6" class="mb-1">
              <div class="form-group">
                <ElLabel
                  class="form-label"
                  for-label="status"
                  :title="$t('Status')"
                  :is-required-icon="true"
                />
                <validation-provider
                  #default="{ errors }"
                  name="Status"
                  rules="required"
                >
                  <select
                    class="form-control"
                    v-model="form.status"
                    :disabled="btnLoading"
                  >
                    <option value="new">
                      {{ $t("New") }}
                    </option>
                    <option value="open">
                      {{ $t("Open") }}
                    </option>
                    <option value="waiting-for-response">
                      {{ $t("Waiting For Response") }}
                    </option>
                    <option value="pending">
                      {{ $t("Pending") }}
                    </option>
                    <option value="resolved">
                      {{ $t("Resolved") }}
                    </option>
                  </select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </div>
            </b-col>
            <b-col cols="12" class="mb-1">
              <div class="form-group">
                <label class="form-label"
                  >{{ $t("Text")
                  }}<span style="color: red">&nbsp;*</span></label
                >

                <MarkdownEditor
                  :content="form.text || ''"
                  @change="form.text = $event"
                  :builder-images="[]"
                  :editable="true"
                  @imageAdded="addCommentImages"
                />
              </div>
            </b-col>

            <b-col cols="12" class="mb-1">
              <div class="dropzone">
                <vue-dropzone
                  id="dropzone"
                  ref="myDropzone"
                  :options="dropzoneOptions"
                />
              </div>
              <showFiles
                :commentFiles="attachments"
                :showImages="true"
                @remove="deleteUpload"
              />
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end mt-2 gap-2">
        <router-link
          to="/tickets"
          class="d-flex align-items-center gap-1 btn btn-secondary"
        >
          <feather-icon icon="XIcon" size="12" />
          <span>{{ $t("Cancel") }}</span>
        </router-link>
        <loading-button
          v-if="$can(`${$route.meta.permission}.create`)"
          @click="submit"
          :loading="btnLoading"
          :class="{ 'cursor-not-allowed': isFormInvalid }"
        >
          <feather-icon class="" icon="PlusIcon" size="12" />
          <span>{{ $t("Create") + " " + $t("Ticket") }}</span>
        </loading-button>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import vueDropzone from "vue2-dropzone";
import ElLabel from "@/components/ElLabel.vue";
import TextInput from "@/components/TextInput.vue";
import PageHeader from "@/components/PageHeader.vue";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
import showFiles from "@/components/ticketSystem/show-files.vue";
import MarkdownEditor from "@/components/MarkdownEditor.vue";
import LoadingButton from "@/components/LoadingButton.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import usersStoreMixin from "@/mixins/usersStoreMixin";

export default {
  mixins: [usersStoreMixin],
  components: {
    vueDropzone: vueDropzone,
    showFiles,
    ElLabel,
    TextInput,
    PageHeader,
    MultiSelectInput,
    MarkdownEditor,
    LoadingButton,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      ticketCreationForms: [],
      areaList: [
        {
          id: "partner",
          name: "Partner",
        },
        {
          id: "customer",
          name: "Customer",
        },
        {
          id: "product",
          name: "Product",
        },
      ],
      form: {
        title: "",
        status: "new",
        priority: "normal",
        text: "",
        images: [],
      },
      attachments: [],
      submitted: false,
      btnLoading: false,
      isLoading: false,
      isCaseReported: false,
      templateId: null,
    };
  },
  computed: {
    ...mapGetters("tickets", ["tickets"]),
    ...mapGetters("users", ["userProfiles"]),
    ...mapGetters(["showLoader"]),
    ...mapGetters("caseLists", ["reportCaseID"]),
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/",
        },
        {
          text: this.$t("Tickets"),
          to: "/tickets",
        },
        {
          text: this.$t("Create"),
          active: true,
        },
      ];
    },
    dropzoneOptions() {
      const component = this; // Capture the component context
      return {
        url: process.env.backEndUrl + "/api/convert-file-to-base64",
        autoProcessQueue: false,
        maxFiles: 5,
        accept: function (file, done) {
          done();
        },
        acceptedFiles:
          ".jpg,.jpeg,.png,.pdf,.csv,.doc,.docx,.ppt,.pptx,.xls,.xlsx",
        dictDefaultMessage: `<i class='fe-upload-cloud'></i>${this.$t(
          "Click or drag file here to upload"
        )}`,
        addRemoveLinks: true,
        init: function () {
          this.on("addedfile", function (file) {
            component.processFile(file); // Use the component context
          });
        },
      };
    },
    isFormInvalid() {
      return (
        !this.form.title ||
        !this.form.priority ||
        !this.form.status ||
        !this.form.text
      );
    },
  },
  beforeDestroy() {
    this.$store.commit("caseLists/reportCaseID", null);
  },
  methods: {
    updateDropzoneMessage() {
      if (this.$refs.myDropzone && this.$refs.myDropzone.dropzone) {
        const dropzoneInstance = this.$refs.myDropzone.dropzone;
        dropzoneInstance.options.dictDefaultMessage = this.$t(
          "Click or drag file here to upload"
        );
        dropzoneInstance.element.querySelector(".dz-message").textContent =
          this.$t("Click or drag file here to upload");
      }
    },
    addCommentImages(event) {
      this.form.images = event;
    },
    customLabel(props) {
      return `${props?.first_name || ""} ${props?.last_name || ""}`;
    },
    deleteUpload(index) {
      this.attachments.splice(index, 1);
    },
    processFile(file) {
      let base64Content = "";

      var reader = new FileReader();
      reader.onload = (event) => {
        const fileName = file.name;
        const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
        const fileExtension = fileName
          .substring(fileName.lastIndexOf(".") + 1)
          .toLowerCase();

        const dataUri = event.target.result;
        const parts = dataUri.split(",");
        if (parts.length === 2) {
          base64Content = parts[1];
          let file = {
            name: fileName,
            type: fileExtension,
            size: `${fileSizeMB} MB`,
            base64: base64Content,
          };
          this.attachments.push(file);
        }
      };
      reader.readAsDataURL(file);
      this.$refs.myDropzone.removeAllFiles();
    },
    async submit() {
      this.btnLoading = true;
      this.submitted = true;
      try {
        this.$refs.simpleRules.validate().then(async (success) => {
          if (success) {
            await this.$store
              .dispatch("tickets/create", {
                ...this.form,
                attachments: this.attachments,
                visibility: "public",
                ...(this.isCaseReported
                  ? { caseId: this.reportCaseID.id }
                  : {}),
              })
              .then(async (response) => {
                const userIds = response.data?.userIds;
                let usersMails = [];
                if (userIds) {
                  await this.getUserProfiles(userIds);
                  usersMails = Object.values(this.userProfiles)
                    .filter((profile) => profile?.email)
                    .map((profile) => profile.email);
                }
                if (this.templateId) {
                  await this.$store.dispatch("mailTemplates/sendMailTemplate", {
                    ...this.templateId,
                    id: this.templateId?.mailTemplateId,
                    from: this.templateId?.senderMail,
                    mails: usersMails,
                    data: {
                      ...this.form,
                      visibility: "public",
                    },
                  });
                }
                this.$router.push("/tickets");
              });
          }
        });
      } catch (e) {
        console.log(e);
      } finally {
        this.btnLoading = false;
      }
      //   }
    },
  },
  async mounted() {
    this.updateDropzoneMessage(); // Set initial message
    this.$watch("$i18n.locale", () => {
      this.updateDropzoneMessage(); // Update message when language changes
    });
    this.$store.commit("showLoader", true);
    if (this.reportCaseID) {
      this.isCaseReported = true;
      this.form.title = "Reported Case - " + this.reportCaseID.name;
    }
    this.$store.commit("showLoader", false);
    const response = await this.$store.dispatch(
      "mailTemplates/mailTemplateAssignmentList"
    );
    this.templateId =
      response?.data?.data.find(
        (template) => template.module === "ticketSystemTemplate"
      ) ?? null;
  },
};
</script>

<style scoped>
li .v-md-editor__tooltip {
  display: none !important;
}
.hidden {
  display: none !important;
}
.tooltip-hidden {
  display: none !important;
}
:deep(.markdown-body) {
  height: 350px;
  background-color: transparent !important;
}
:deep(.dropzone) .dz-preview {
  z-index: 1 !important;
}
</style>
